'use client';

import { useRouter, useSearchParams } from 'next/navigation';
import { useState, useEffect } from 'react';
import { Button, Input } from '@mui/material';
import { createClient } from '@/supabase/utils/client';
import { setUserType } from '@/functions/getUserType';
import { NAMED } from '@/types';
import { authLogger } from '@/debug/auth';
import { SESSION_USER_ID_FOR_CHECK_SYNC } from '@/functions/constants';
import { addUsageRecordIfNotExists } from '@/functions/usage/addUsageRecordIfNotExists';
export default function Login() {
  if (process.env.NODE_ENV !== 'development') {
    return <>"This route is only available in development mode."</>;
  }
  const [email, setEmail] = useState('test@opentutorials.org');
  const [password, setPassword] = useState('111111');
  const searchParams = useSearchParams();
  const redirectPath = searchParams.get('redirect');
  const router = useRouter();
  const handleSignUp = async () => {
    authLogger('회원가입 시도', {
      email
    });
    const supabase = createClient();
    const {
      error,
      data
    } = await supabase.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: `${location.origin}/auth/callback`
      }
    });
    authLogger('회원가입 결과', {
      error,
      data
    });
    if (data.user) {
      document.cookie = `${SESSION_USER_ID_FOR_CHECK_SYNC}=${data.user.id}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
      await addUsageRecordIfNotExists(supabase);
    }
    await handleSignInCallback(error, data);
  };
  const handleSignIn = async () => {
    authLogger('패스워드로 로그인 시도', {
      email
    });
    const supabase = createClient();
    const {
      error,
      data
    } = await supabase.auth.signInWithPassword({
      email,
      password
    });
    authLogger('패스워드로 로그인 결과', {
      error,
      data
    });
    if (data.user) {
      document.cookie = `${SESSION_USER_ID_FOR_CHECK_SYNC}=${data.user.id}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
    }
    await handleSignInCallback(error, data);
  };
  return <div className={`flex flex-col justify-center items-center h-screen dark:text-white`} data-sentry-component="Login" data-sentry-source-file="page.tsx">
            <div className="flex flex-col gap-2">
                <div>
                    <div>
                        <Input name="email" placeholder="email" onChange={e => setEmail(e.target.value)} value={email} className="bg-white px-2 rounded-md" data-sentry-element="Input" data-sentry-source-file="page.tsx" />
                    </div>
                    <div>
                        <Input type="password" name="password" placeholder="password" onChange={e => setPassword(e.target.value)} value={password} className="bg-white px-2 mt-2 rounded-md" data-sentry-element="Input" data-sentry-source-file="page.tsx" />
                    </div>
                </div>
                <div className="flex gap-2">
                    <div>
                        <Button onClick={handleSignUp} variant="contained" data-sentry-element="Button" data-sentry-source-file="page.tsx">
                            Sign up
                        </Button>
                    </div>
                    <div>
                        <Button onClick={handleSignIn} variant="contained" data-sentry-element="Button" data-sentry-source-file="page.tsx">
                            Sign in
                        </Button>
                    </div>
                </div>
            </div>
        </div>;

  // @ts-ignore
  async function handleSignInCallback(error, data) {
    if (error) {
      authLogger('로그인 실패', {
        email,
        error
      });
      alert('로그인 실패 : ' + JSON.stringify(error));
    } else {
      setUserType(NAMED);
      document.cookie = `${SESSION_USER_ID_FOR_CHECK_SYNC}=${data.user.id}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
      authLogger('패스워드로 로그인 처리 완료 : ', {
        data
      });
      // redirect to /home
      router.push(redirectPath ? redirectPath : '/home');
    }
  }
}